
export function DDTimes(data:string) {  //2020-04-26 10:20:36
	data = String( data )
	let time = data.split(" ");
	let year = time[0].split("-");
	let times = time[1].split(":");
	let newDateTime = "";
	let dates = new Date();
	if( Number(year[0]) === Number(dates.getFullYear()) ){
		if( Number( year[1]) === Number( dates.getMonth()+1 )  ){
			if( Number( year[2]) === Number( dates.getDate() )  ){
				if( Number( times[0] ) === Number( dates.getHours() ) ){
					if( Number( times[1] ) <= Number( dates.getMinutes() )){
						newDateTime = ( Number( dates.getMinutes() ) - Number( times[1] ) ) + "分钟前"
					}else if( (Number( dates.getHours() )  - Number(times[0])) === 1 ){
						if( ((Number( dates.getMinutes() ))  - Number(times[1])) > 0 ){
							newDateTime= ( Number( dates.getMinutes() ) - Number( times[1] ) ) + "分钟前"
						}else{
							let val = Number(times[1]) + 60;
							newDateTime = ( val - Number( dates.getMinutes() ) ) + "分钟前"
						}
					}
				}else{
					newDateTime = ( Number( dates.getHours() ) - Number( times[0] ) ) + "小时前"
				}
			}else{
				newDateTime = (  Number( dates.getDate() ) - Number( year[2] ) ) + "日前"
			}
		}else{
			newDateTime = ( Number( dates.getMonth()+1 ) - Number( year[1] ) ) + "月前"
		}
	}else{
		newDateTime = time[0]//( (dates.getFullYear()) - Number(year[0]) ) + "年" + year[1] + "月" + year[2] + "日"
	}
	return newDateTime;
}



import store from "../../index"
import { GetTimeType,GetFromType,AllExpressType,SizeType,MyComponentType } from "../../../Type/index"
import { UpListItem } from "@/Type/StoreType"
import { UpDataType,MyUpDataType } from "../../../Api/Express/interfaceType"
import { Module,VuexModule,getModule,Mutation } from "vuex-module-decorators"

interface IndexType{
    TimeIndex:number;
    FromIndex:number;
    ToIndex:number,
    UpIndex?:number,
}
interface IssueIndex<T=number>{
    FromIndex:T;
    SizeIndex:T;
    TimeIndex:T;
}
interface IssueValue<T=string>{
    Money:number|T;
    KeyVal:T;
    MessageVal:T;
}
interface ScrollValue<T=number>{
    AllScroll:T;
    MyScroll:T;
}

export interface ExpressType<T=string>{
    TimeList:Array<GetTimeType>;
    FromList:Array<GetFromType>;
    SizeList:Array<SizeType>;
    ExpressList:Array<AllExpressType>
    ExpressUpData:UpDataType;// 广场请求的UpData 参数
    ExpressIndex:IndexType; // 选择筛选的 条件索引
    IssueIndex:IssueIndex;
    IssueValue:IssueValue;
    IssueList:UpListItem[]; // 发布多个代取
    MyExpressType:MyUpDataType;
    MyExpressList:MyComponentType[];
    ExpressScroll:ScrollValue

    SetTimeList(data:Array<GetTimeType>):void;
    SetFromList(data:Array<GetFromType>):void;
    SetSizeList(data:Array<SizeType>):void;
    SetExpressList(data:Array<AllExpressType>):void;
    SetExpressUpData(data:UpDataType):void;
    SetExpressIndex(data:IndexType):void;
    SetIssueIndex(data:IssueIndex):void;
    SetIssueValue(data:IssueValue):void;
    SetIssueList(data:UpListItem[]):void;// 发布多个代取
    SetMyType(data:MyUpDataType):void;
    SetMyList(data:MyComponentType[]):void;
    SetExpressScroll(data:ScrollValue):void
}


@Module({
    name:"Express",
    store,
    dynamic:true
})
export default class Express extends VuexModule implements ExpressType{
    TimeList:Array<GetTimeType> = []
    FromList:Array<GetFromType> = []
    SizeList:Array<SizeType> = []
    ExpressList:Array<AllExpressType> = []
    ExpressUpData:UpDataType = {
        pageNo:1,
        pageSize:15
    }
    ExpressIndex:IndexType = {
        TimeIndex:0,
        FromIndex:0,
        ToIndex:0,
        UpIndex:0
    }
    IssueIndex:IssueIndex = {
        FromIndex:0,
        SizeIndex:0,
        TimeIndex:0,
    }
    IssueValue:IssueValue = {
        Money:0,
        KeyVal:"",
        MessageVal:"",
    }
    IssueList:UpListItem[] = []
    MyExpressType:MyUpDataType = {
        pageNo:1,
        pageSize:15
    }
    MyExpressList:MyComponentType[] = []
    ExpressScroll: ScrollValue<number> = {
        AllScroll:0,
        MyScroll:0,
    }
    
    // 时间
    get GetTimeList(){
        return this.TimeList
    }
    @Mutation
    SetTimeList(data:Array<GetTimeType>){
        this.TimeList = data
    }
    // 取件地址
    get GetFromList(){
        return this.FromList
    }
    @Mutation
    SetFromList(data:Array<GetFromType>){
        this.FromList = data
    }
    // 货物大小
    get GetSizeList(){
        return this.SizeList
    }
    @Mutation
    SetSizeList(data:Array<SizeType>){
        this.SizeList = data
    }
    // 代取广场列表
    get GetExpressList(){
        return this.ExpressList
    } 
    @Mutation
    SetExpressList(data:Array<AllExpressType>){
        this.ExpressList = data
    }
    // 代取广场 请求数据
    get GetExpressUpData(){
        return this.ExpressUpData
    }
    @Mutation
    SetExpressUpData(data:UpDataType){
        this.ExpressUpData = data
    }
    // 代取筛选条件
    get GetExpressIndex(){
        return this.ExpressIndex
    }
    @Mutation
    SetExpressIndex(data:IndexType){
        this.ExpressIndex = data
    }
    // 发布代取选择条件的索引
    get GetIssueIndex(){
        return this.IssueIndex
    }
    @Mutation
    SetIssueIndex(data:IssueIndex){
        this.IssueIndex = data
    }
    // 发布代取 输入的参数
    get GetIssueValue(){
        return this.IssueValue
    }
    @Mutation
    SetIssueValue(data:IssueValue){
        this.IssueValue = data
    }
    //发布代取列表
    get GetIssueList(){
        return this.IssueList
    }
    @Mutation
    SetIssueList(data:UpListItem[]){
        this.IssueList = data
    }
    //我的代取列表请求
    get GetMyType(){
        return this.MyExpressType
    }
    @Mutation
    SetMyType(data: MyUpDataType<string>): void {
        this.MyExpressType = data
    }
    //我的代取列表
    get GetMyList(){
        return this.MyExpressList
    }
    @Mutation
    SetMyList(data: MyComponentType[]): void {
        this.MyExpressList = data
    }
    // 滚动条值
    get GetExpScroll(){
        return this.ExpressScroll
    }
    @Mutation
    SetExpressScroll(data: ScrollValue<number>): void {
        this.ExpressScroll = data
    }
}

export const StoreExpress = getModule( Express )

import Axios from "../Config/exp"
import {
    UpDataType,
    IssueUpData,
    MyUpDataType,
    ChangeUpDataType,
    ChangeStateType
 } from "./interfaceType"

interface HeadType<T=string>{
    userId:T|number;
    token:T;
}
interface DefaultBodyType<T=string,Y=number>{
    pageNo:T|Y;
    pageSize:T|Y;
}

// 代取广场
export function AllExpress( headers:HeadType,body:UpDataType ):Promise<any>{
    return (
        Axios({
            url:"/campus/mailDemand/list",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 查询 用户 代取
export function FindExpress( headers:HeadType,body:UpDataType ):Promise<any>{
    return (
        Axios({
            url:"/campus/mailDemand/queryFuzzyOrder",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 我的代取列表
export function MyExpress( headers:HeadType,body:MyUpDataType ):Promise<any>{
    return (
        Axios({
            url:"/campus/mailDemand/list4useId",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 代取详情
export function ExpressSuc( headers:HeadType,body:{ orderId:string } ):Promise<any>{
    return (
        Axios({
            url:"/campus/mailDemand/detail",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 修改订单信息
export function ChangeOrder( headers:HeadType,body:ChangeUpDataType ):Promise<any>{
    return (
        Axios({
            url:"/campus/mailDemand/updateMailCompany",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 取消订单
export function BackOrder( headers:HeadType,body:{ id?:string|number;orderId?:string|number; } ):Promise<any>{
    return (
        Axios({
            url:"/campus/mailDemand/cancal",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 退款
export function BackPay( headers:HeadType,body:{ orderId:string|number } ):Promise<any>{
    return (
        Axios({
            url:"/app/weixin/refund",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 请求取件地址
export function GetFrom( headers:HeadType ):Promise<any>{
    return (
        Axios({
            url:"/campus/mailCompany/list",
            method:"POST",
            headers,
            data:{}
        })
    )
}

// 发布代取快递
export function IssueExpress( headers:HeadType,body:IssueUpData ):Promise<any>{
    return (
        Axios({
            url:"/campus/mailDemand/pushMailDemand",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 请求参数
export function IssuePay( headers:HeadType,body:{ orderId:string } ):Promise<any>{
    return (
        Axios({
            url:"/app/weixin/weixinApiPay",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 请求支付 回调
export function IssuePayBack( headers:HeadType,body:{ orderId:string } ):Promise<any>{
    return (
        Axios({
            url:"/app/weixin/callBack",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 异常订单 领取优惠卷
export function ErrorCoupon( headers:HeadType,body:{ orderId:string } ):Promise<any>{
    return (
        Axios({
            url:"/campus/mailDemand/errorOrderReceiveCoupon",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 修改订单状态
export function ChangeState( headers:HeadType,body:ChangeStateType ):Promise<any>{
    return (
        Axios({
            url:"/campus/mailDemand/updateStatus",
            method:"POST",
            headers,
            data:body
        })
    )
}
